<template>
    <div class="page">
      <ProposalForm
      :isCouncil="isCouncil"
      :tokenInfo="tokenInfo"
      :tokenAuth="tokenAuth"
      :loadingApprove="loadingApprove"
      :loadingAdd="loadingAdd"
      @approveToken="approveToken"
      @addProposal="handleAdd"/>
    </div>
</template>

<script>
import ProposalForm from './components/ProposalForm.vue'
import daoObj from '../../contracts/contractDao'
import tokenObj from '../../contracts/contractToken'
import config from '../../config/app.config'

export default {
  name: '',
  components: { ProposalForm },
  data () {
    return {
      point: 0.000001,
      daoAt: '',
      isCouncil: false,
      daoInfo: {
        token: ''
      },
      tokenInfo: {
        name: '-',
        totalSupply: 0
      },
      tokenAuth: true,
      loadingApprove: false,
      loadingAdd: false
    }
  },
  methods: {
    init () {
      this.getDaoId()
    },
    async getDaoId () {
      this.daoAt = this.$route.query.daoAt || ''
      if (!this.daoAt) this.$router.replace('/detail')
      this.getIsCouncil()
      await this.getDaoInfo()
      this.getTokenInfo()
    },
    async getIsCouncil () {
      const resp = await daoObj.councliAt(this.daoAt)
      if (!resp.success) return
      if (!resp.result) this.isCouncil = false
      else this.isCouncil = true
    },
    async getDaoInfo () {
      const resp = await daoObj.daoDetail(this.daoAt)
      if (!resp.success) return
      this.daoInfo = resp.result
    },
    async getTokenInfo () {
      this.getSymbol()
      this.getTotalSupply()
      await this.getTokenAllance()
    },
    async getSymbol () {
      const resp = await tokenObj.getSymbol(this.daoInfo.token)
      if (!resp.success) return
      this.tokenInfo.name = resp.result
    },
    async getTotalSupply () {
      const resp = await tokenObj.totalSupply(this.daoInfo.token)
      if (!resp.success) return
      this.tokenInfo.totalSupply = resp.result
    },
    async getTokenAllance () {
      const resp = await tokenObj.allowance(this.daoInfo.token, config.daoAddress)
      if (!resp.success) return
      const allowanceAmount = +resp.result
      this.tokenAuth = allowanceAmount >= (this.tokenInfo.totalSupply * this.point)
    },
    async approveToken () {
      this.loadingApprove = true
      const resp = await tokenObj.approve(this.daoInfo.token, config.daoAddress)
      this.loadingApprove = false
      if (!resp.success) return
      this.tokenAuth = true
    },
    async handleAdd (params1, params2, mutilOption, options) {
      this.loadingAdd = true
      
      const resp = await daoObj.proposalAdd(this.daoAt, params1, params2, mutilOption, options)
      this.loadingAdd = false
      if (!resp.success) return
      this.$toast('success')
      this.$gbUtils.handleToPage('/detail')
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 0 15px 20px 15px;
}
</style>
